import { FC, MouseEventHandler } from 'react';
import { Typography, Row, Button } from '@cfa/react-components';
import { IconWithShadow } from '../Styled/IconWithShadow';
import styled from 'styled-components';
import { themeSizes, themeColors } from '../../styles/globalStyleVars';
import {
  IconAlertOctagon,
  IconCalendarEvent,
  IconLockAccess,
  IconTicket,
} from '@tabler/icons-react';
import { generateErrorOptions } from '../../utils/config/errorConfig';

const ErrorRow = styled(Row)<{ $page: string }>`
  margin-bottom: ${themeSizes.xl};
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  &:first-of-type {
    margin-top: ${props =>
      ['home', 'modal'].includes(props.$page)
        ? themeSizes.md
        : props.$page === 'search'
        ? '80px'
        : props.$page === 'profile'
        ? themeSizes.xxl
        : '88px'};
    margin-bottom: ${themeSizes.sm};
  }
  &:last-of-type {
    ${props => props.$page !== 'modal' && 'margin-bottom: 328px'};
  }
`;

const ErrorHeading = styled(Typography)`
  margin-top: ${themeSizes.md};
`;

interface Props {
  btnClick: MouseEventHandler<HTMLButtonElement> | (() => void);
  errorType: string;
  page: string;
  isConfidential?: boolean | undefined;
  btnPrimary?: boolean;
}

const FetchError: FC<Props> = ({
  btnClick,
  errorType,
  page,
  isConfidential,
  btnPrimary,
}) => {
  const errorOptions = generateErrorOptions(page, errorType, isConfidential);

  if (!errorOptions) return <></>;

  const generateIconComponent = () => {
    const tablerIconProps = {
      size: 38,
      color: themeColors.colorGray_6,
    };
    switch (errorType) {
      case 'insufficient':
        return {
          tablerIcon: <IconLockAccess {...tablerIconProps} />,
          iconName: 'Lock Access',
        };

      case 'ticketNotFound':
        return {
          tablerIcon: <IconTicket {...tablerIconProps} />,
          iconName: 'Ticket',
        };
      case 'appointmentNotFound':
        return {
          tablerIcon: <IconCalendarEvent {...tablerIconProps} />,
          iconName: 'Calendar Event',
        };
      default:
        return {
          tablerIcon: <IconAlertOctagon {...tablerIconProps} />,
          iconName: 'Alert Octagon',
        };
    }
  };

  return (
    <>
      <ErrorRow $page={page}>
        <IconWithShadow {...generateIconComponent()} />
        <ErrorHeading variant='h3'>{errorOptions.errorTitle}</ErrorHeading>
      </ErrorRow>
      <ErrorRow $page={page}>
        <Typography style={{ whiteSpace: 'pre-line' }}>
          {errorOptions.errorMessage}
        </Typography>
        {errorOptions.errorSecondaryMessage && (
          <Typography>{errorOptions.errorSecondaryMessage}</Typography>
        )}
      </ErrorRow>
      {errorOptions.showButton && (
        <ErrorRow $page={page}>
          <Button
            color={btnPrimary ? 'primary' : 'secondary'}
            onClick={btnClick}
            size='sm'
            variant='outlined'
          >
            {errorOptions.buttonText}
          </Button>
        </ErrorRow>
      )}
    </>
  );
};
export default FetchError;

import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { getLocation } from '../utils/api/requests/requests';
import { Location } from '../interfaces/LocationInterface';
import { corpLocationData } from '../utils/config/locationConfig';
export interface LocationState {
  userLocations: Location[];
  userLocationsError: boolean;
  refetchLoading: boolean;
  activeLocation: string;
  locationAssigned: boolean;
  locationDetailsLoading: boolean;
  isModalLocationUpdate: boolean;
  subscribedLocations: string[];
  setLocationDetailsLoading: (val: boolean) => void;
  setRefetchLoading: (val: boolean) => void;
  setIsModalLocationUpdate: (val: boolean) => void;
  setActiveLocation: (loc: string) => void;
  setLocationAssigned: (val: boolean) => void;
  setUserLocations: (loc: string[], token?: Nullable<string>) => void;
  setSubscribedLocations: (locs?: string[]) => void;
}

export const useLocationStore = create<LocationState>()(
  persist(
    set => ({
      userLocations: [],
      userLocationsError: false,
      refetchLoading: false,
      activeLocation: '',
      locationAssigned: false,
      locationDetailsLoading: false,
      isModalLocationUpdate: false,
      subscribedLocations: [],
      setActiveLocation: activeLocation => set({ activeLocation }),
      setRefetchLoading: refetchLoading => {
        set({ userLocationsError: false });
        set({ refetchLoading });
      },
      setIsModalLocationUpdate: isModalLocationUpdate =>
        set({ isModalLocationUpdate }),
      setLocationDetailsLoading: locationDetailsLoading => {
        set({ userLocationsError: false });
        set({ locationDetailsLoading });
      },
      setLocationAssigned: locationAssigned => set({ locationAssigned }),
      setUserLocations: async (locs, token) => {
        const fetchLocations = async (
          locs: string[],
          token?: Nullable<string>
        ) => {
          const locations: Location[] = [];
          for (const loc of locs) {
            if (loc === '00000') {
              locations.push(corpLocationData);
              return locations;
            }
            try {
              const result = await getLocation(loc, token);
              locations.push(result as Location);
            } catch (error) {
              set({ userLocationsError: true });
            }
          }
          return locations.sort(function (a, b) {
            return Number(a.number) - Number(b.number);
          });
        };
        set({ userLocations: await fetchLocations(locs, token) });
        set({ locationDetailsLoading: false });
        set({ refetchLoading: false });
      },
      setSubscribedLocations: subscribedLocations =>
        set({ subscribedLocations }),
    }),
    {
      partialize: state => ({ activeLocation: state.activeLocation }),
      name: 'location-storage',
    }
  )
);

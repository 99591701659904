import { DateTime } from 'luxon';

export const formatFutureDate = (date: DateTime) => {
  const dt = date.startOf('day');
  const now = DateTime.now().setZone(date.zoneName).startOf('day');
  const diff = dt.diff(now, ['days']).toFormat('d');

  if (parseInt(diff) === 0) {
    return 'Today';
  }
  if (parseInt(diff) === 1) {
    return 'Tomorrow';
  }
  if (parseInt(diff) <= 7) {
    return dt.toLocaleString({
      weekday: 'long',
    });
  }
  return dt.toLocaleString({
    day: '2-digit',
    month: 'long',
  });
};

export const isThisWeek = (date: string) => {
  const dateDiff = parseInt(
    DateTime.fromISO(date).diff(DateTime.now(), ['days']).toFormat('d')
  );
  return dateDiff <= 6 && dateDiff >= 0;
};

export const afterThisWeek = (date: string) => {
  const dateDiff = parseInt(
    DateTime.fromISO(date).diff(DateTime.now(), ['days']).toFormat('d')
  );
  return dateDiff >= 7;
};

export const isWithinHoursLimit = (date: string, cutOff: number) => {
  const dateDiff = parseInt(
    DateTime.fromISO(date).diff(DateTime.now(), ['minutes']).toFormat('m')
  );
  const pastHourLimit = -(cutOff * 60); // convert cutOff to minutes
  return dateDiff > pastHourLimit;
};

export const isFutureDatedWithCutoff = (date: string, cutOff: number) => {
  const now = DateTime.now();
  const dateToTest = DateTime.fromISO(date);
  return now <= dateToTest || isWithinHoursLimit(date, cutOff);
};

export const isLastSixMonths = (date: string) => {
  const now = DateTime.now();
  const pastDate = DateTime.fromISO(date);

  const monthDiff = now.diff(pastDate, ['months']).toObject().months;

  return monthDiff && monthDiff <= 6;
};

export const getShortTimeZone = (
  date: string,
  timeZone: string | undefined
) => {
  return DateTime.fromISO(date, {
    zone: timeZone,
    locale: 'en',
  }).toFormat('ZZZZ');
};

export const longDateFormat = (date: DateTime) => {
  return date.toLocaleString({
    year: 'numeric',
    month: 'short',
    day: '2-digit',
  });
};

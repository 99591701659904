import { lazy, FC } from 'react';
import '@cfa/react-components/dist/grid.min.css';
import '@cfa/react-components/dist/styles.min.css';
import { BrowserRouter as Router, useHistory } from 'react-router-dom';
import { Security } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { oktaAuthConfig } from '../config';
import { QueryClient, QueryClientProvider } from 'react-query';
import './App.scss';
import UserProvider from '../user/UserContext';
import { OrientationPrompt } from '../components/Global/OrientationPrompt';
import { BugsnagErrorBoundary } from '../utils/BugSnag/BugsnagErrorBoundary';
import { AppAuthWrapper } from './AppAuthWrapper';
import { Routes } from './Routes';
import SessionTimeoutModal from '../components/Global/Modals/SessionTimer/SessionTimeoutModal';

const PageNotFound = lazy(() => import('../pages/PageNotFound/PageNotFound'));

const App: FC = () => {
  const oktaAuth = new OktaAuth(oktaAuthConfig);
  const history = useHistory();

  const restoreOriginalUri = async (
    _oktaAuth: unknown,
    originalUri: string
  ) => {
    history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
  };

  const triggerLogin = async () => {
    await oktaAuth.signInWithRedirect();
  };

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        notifyOnChangeProps: 'tracked',
      },
    },
  });

  const FallbackError = () => {
    return <PageNotFound />;
  };

  return (
    <Security
      oktaAuth={oktaAuth}
      onAuthRequired={triggerLogin}
      restoreOriginalUri={restoreOriginalUri}
    >
      {/* Edit this component to add more links to the navigation bar! */}
      <BugsnagErrorBoundary FallbackComponent={FallbackError}>
        <UserProvider>
          <QueryClientProvider client={queryClient}>
            <AppAuthWrapper>
              <Routes />
              <SessionTimeoutModal />
            </AppAuthWrapper>
          </QueryClientProvider>
          <OrientationPrompt />
        </UserProvider>
      </BugsnagErrorBoundary>
    </Security>
  );
};

const AppWithRouterAccess: FC = () => {
  return (
    <Router>
      <App />
    </Router>
  );
};

export default AppWithRouterAccess;

import { useMutation, useQueryClient } from 'react-query';
import {
  SelectedContact,
  TicketInterface,
} from '../../../interfaces/TicketInterface';
import {
  markTicketAsRead,
  markLocationAsVisited,
  changeTicketContact,
  requestTicketUpdate,
  closeTicket,
  changeTicketStatus,
  submitFormToCreateCase,
  deleteRecentSearch,
  submitKBFeedback,
  markAssetViewed,
  updateCaseSubscription,
  markUserOnboarding,
  changeUserNotificationPreference,
  submitCasePrompt,
  trackUserEvent,
} from './requests';
import { useNotificationsStore } from '../../../stores/globalNotificationStore';
import { callEvent } from '../../Amplitude/AmplitudeUtilities';
import { ErrorInterface } from '../../../interfaces/ErrorInterface';
import { FormSubmissionVariables } from '../../../interfaces/SupportFormInterface';
import { useHistory } from 'react-router-dom';
import { KBFeedbackInterface } from '../../../interfaces/KnowledgeInterface';
import { usePaginationStore } from '../../../stores/paginationStore';
import { Dispatch, SetStateAction } from 'react';
import {
  updateSubscriptionListSubscribe,
  updateSubscriptionListUnsubscribe,
} from '../../Airship/AirshipUtilities';
import {
  UserEventInfoFilter,
  UserEventType,
} from '../../../interfaces/UserEventInterface';

export const useMarkTicketAsRead = (id: string) =>
  // TODO: Possibly update query cache as a result of mutation
  useMutation({
    mutationFn: () => markTicketAsRead(id),
  });

export const useMarkLocationAsVisited = (
  locationNum?: string,
  locationName?: string
) =>
  useMutation({
    mutationFn: () => markLocationAsVisited(locationNum, locationName),
  });

export const useChangeTicketContact = (
  ticket: TicketInterface,
  selectedChangeContact: SelectedContact,
  activeLocation: string,
  setShowChangeModal: (arg: boolean) => void,
  subscribeViaMessageBlock: () => void,
  showContactChangeMessageBlock?: boolean,
  setTimeLeft?: (value: number | undefined) => void
) => {
  const queryClient = useQueryClient();
  const { addNotification } = useNotificationsStore();
  return useMutation({
    mutationFn: () =>
      changeTicketContact(
        ticket?.id as string,
        selectedChangeContact?.id as string,
        selectedChangeContact?.guid as string,
        ticket?.contact?.guid as string,
        activeLocation
      ),
    onMutate: async () => {
      await queryClient.cancelQueries({ queryKey: ['get-ticket', ticket?.id] });

      const previousDetails = queryClient.getQueryData([
        'get-ticket',
        ticket?.id,
      ]);
      // Optimistically update to the new value
      queryClient.setQueryData(
        ['get-ticket', ticket?.id],
        (oldData: unknown) => {
          return oldData
            ? {
                ...oldData,
                contact: {
                  firstName: selectedChangeContact?.firstName,
                  fullName: selectedChangeContact?.fullName,
                  guid: selectedChangeContact?.guid,
                  lastName: selectedChangeContact?.lastName,
                },
              }
            : oldData;
        }
      );
      return { previousDetails };
    },
    onError: (error, variables, context) => {
      context &&
        queryClient.setQueryData(
          ['get-ticket', ticket?.id],
          context.previousDetails
        );
    },
    onSuccess: () => {
      if (showContactChangeMessageBlock) {
        addNotification({
          severity: 'success',
          regularTextContent: ` is now the ticket contact and will receive notifications for this ticket. \nSubscribe to this ticket if you also want to receive notifications.`,
          boldTextContent: `${selectedChangeContact?.fullName}`,
          contentReverse: false,
          actionText: 'Subscribe to Updates',
          actionLinkOnClick: () => {
            subscribeViaMessageBlock();
            setTimeLeft && setTimeLeft(60);
          },
        });
      } else {
        addNotification({
          severity: 'success',
          regularTextContent: 'Ticket contact has been changed to',
          boldTextContent: `${selectedChangeContact?.fullName}.`,
          contentReverse: true,
          timeToDisplay: 5000,
        });
      }
      queryClient.invalidateQueries({
        queryKey: ['get-ticket', ticket?.id],
      });
      callEvent('change case contact', {
        'case number': ticket?.number,
        locationNum: activeLocation,
      });
    },
    onSettled: () => {
      setShowChangeModal(false);
      queryClient.invalidateQueries({
        queryKey: ['all-tickets', activeLocation],
        refetchInactive: true,
      });
    },
  });
};

export const useCloseTicket = (
  ticketId: string,
  ticketNumber: string,
  activeLocation: string,
  setTicketJustClosed: (arg: boolean) => void,
  setTicketCanClose?: (arg: boolean) => void,
  comment?: string
) => {
  const queryClient = useQueryClient();
  const { clearNotifications } = useNotificationsStore();
  return useMutation({
    mutationFn: () => closeTicket(ticketId as string, comment as string),
    onMutate: async () => {
      await queryClient.cancelQueries({ queryKey: ['get-ticket', ticketId] });

      const previousDetails = queryClient.getQueryData([
        'get-ticket',
        ticketId,
      ]);

      return { previousDetails };
    },
    onError: (error: ErrorInterface) => {
      if (error.response?.errors[0].errorInfo === 403) {
        setTicketCanClose && setTicketCanClose(false);
        queryClient.invalidateQueries({
          queryKey: ['get-ticket', ticketId],
        });
      }
      callEvent('close case error', {
        'case number': ticketNumber,
        locationNum: activeLocation,
      });
    },
    onSuccess: () => {
      clearNotifications();
      setTicketJustClosed(true);
      callEvent('close case', {
        'case number': ticketNumber,
        locationNum: activeLocation,
      });
    },
    onSettled: data => {
      queryClient.setQueryData(['get-ticket', ticketId], (oldData: unknown) => {
        return oldData
          ? {
              ...oldData,
              ...data,
            }
          : oldData;
      });
      setTimeout(() => {
        setTicketJustClosed(false);
      }, 2000);
      queryClient.invalidateQueries({
        queryKey: ['all-tickets', activeLocation],
        refetchInactive: true,
      });
    },
  });
};

export const useRequestTicketUpdate = (
  ticketId: string,
  activeLocation: string
) => {
  const queryClient = useQueryClient();
  const { addNotification, setLoadingNotification } = useNotificationsStore();
  return useMutation({
    mutationFn: () => requestTicketUpdate(ticketId as string),
    onMutate: () => {
      setLoadingNotification();
      queryClient.cancelQueries({
        queryKey: ['get-ticket', ticketId],
      });

      const previousDetails = queryClient.getQueryData([
        'get-ticket',
        ticketId,
      ]);

      // Optimistically update to the new value
      queryClient.setQueryData(['get-ticket', ticketId], (oldData: unknown) => {
        return oldData
          ? {
              ...oldData,
              canRequestUpdate: false,
            }
          : oldData;
      });
      return { previousDetails };
    },
    onError: (error, variables, context) => {
      context &&
        queryClient.setQueryData(
          ['get-ticket', ticketId],
          context.previousDetails
        );
    },
    onSuccess: () => {
      addNotification({
        severity: 'success',
        regularTextContent: 'Status Requested.',
        boldTextContent:
          'Request Status for this case will be available again in 24 hours.',
        contentReverse: true,
        timeToDisplay: 5000,
      });
      queryClient.invalidateQueries({
        queryKey: ['get-ticket', ticketId],
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ['all-tickets', activeLocation],
        refetchInactive: true,
      });
    },
  });
};

export const useReopenTicket = (
  ticketId: string,
  ticketNumber: string,
  activeLocation: string,
  setRetryLoading: (arg: boolean) => void
) => {
  const queryClient = useQueryClient();
  const { clearNotifications } = useNotificationsStore();
  return useMutation({
    mutationFn: (inputComment: string) =>
      changeTicketStatus('OPEN', ticketId, inputComment as string),
    onMutate: async () => {
      clearNotifications();
      callEvent('reopen ticket', {
        'case number': ticketNumber,
        locationNum: activeLocation,
      });
      await queryClient.cancelQueries({ queryKey: ['get-ticket', ticketId] });
    },
    onError: () => {
      callEvent('reopen ticket error', {
        'case number': ticketNumber,
        locationNum: activeLocation,
      });
    },
    onSettled: data => {
      setRetryLoading(false);
      queryClient.invalidateQueries({
        queryKey: ['all-tickets', activeLocation],
        refetchInactive: true,
      });
      queryClient.setQueryData(['get-ticket', ticketId], oldData => {
        return oldData
          ? {
              ...oldData,
              ...data,
            }
          : oldData;
      });
    },
  });
};

export const useSubmitFormToCreateCase = (formID: string, redirect = true) => {
  const history = useHistory();
  const queryClient = useQueryClient();
  const { setTicketPageNumber } = usePaginationStore();

  return useMutation({
    mutationFn: ({
      locationNum,
      variables,
      formTitle,
      formAttachments,
    }: FormSubmissionVariables) =>
      submitFormToCreateCase(
        locationNum,
        formID,
        variables,
        formTitle,
        formAttachments
      ),
    onError: (error: ErrorInterface, variables) => {
      callEvent('form submit error', {
        'form id': formID,
        locationNum: variables.locationNum,
        'error message': error.response?.errors[0].errorType,
      });
    },
    onSuccess: (data, variables) => {
      const { id, attachmentErrors } = data;
      setTicketPageNumber(1);
      if (redirect) {
        history.push(`/tickets/${variables.locationNum}/${id}`, {
          newTicket: true,
          attachmentErrors,
          formID,
        });
      }
      if (formID === 'major_issue_assoc') {
        const majorCaseId = variables.variables.find(
          o => o.id === 'parent_case'
        )?.value;
        queryClient.invalidateQueries({
          queryKey: ['get-related-minor-cases', majorCaseId],
        });
      }
    },
  });
};

export const useDeleteRecentSearch = (
  userGuid: string,
  recentSearchResult: string
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => deleteRecentSearch(userGuid, [recentSearchResult]),
    onMutate: async () => {
      await queryClient.cancelQueries({
        queryKey: ['get-recent-searches', userGuid],
      });
    },
    onSuccess: async () => {
      callEvent('remove recent search', {
        'search term': recentSearchResult,
      });
      await queryClient.invalidateQueries({
        queryKey: ['get-recent-searches', userGuid],
      });
    },
  });
};

export const useSubmitKBFeedback = () =>
  useMutation({
    mutationFn: ({
      articleID,
      positive,
      reasonCode,
      comment,
    }: KBFeedbackInterface) =>
      submitKBFeedback(articleID, positive, reasonCode, comment),
  });

export const useMarkAssetViewed = (input: Record<string, unknown>) =>
  useMutation({
    mutationFn: () => markAssetViewed(input),
  });

export const useUpdateSubscription = (
  caseId: string,
  subscribe: boolean,
  activeLocation: string,
  caseNumber: string,
  isSmallScreen: boolean,
  setIsSubscribed: Dispatch<SetStateAction<boolean>>,
  assignToMeSelected: boolean,
  guid: string
) => {
  const { addNotification, setLoadingNotification } = useNotificationsStore();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: () => updateCaseSubscription(caseId, subscribe),
    onMutate: () => isSmallScreen && setLoadingNotification(),
    onSuccess: data => {
      setIsSubscribed(data);
      !assignToMeSelected &&
        addNotification({
          severity: 'success',
          boldTextContent: subscribe
            ? `Subscribed to ticket updates.`
            : `Unsubscribed from ticket updates.`,
          regularTextContent: subscribe
            ? `You will now receive notifications for any updates on this ticket.`
            : `You will no longer receive notifications for any updates on this ticket.`,
          timeToDisplay: 5000,
        });

      callEvent(!subscribe ? 'unsubscribe from case' : 'subscribe to case', {
        locationNum: activeLocation,
        'case number': caseNumber,
      });

      queryClient.invalidateQueries({
        queryKey: ['get-airship-user-properties', guid],
      });
    },
    onError: () => {
      callEvent('subscribe or unsubscribe error', {
        locationNum: activeLocation,
        'case number': caseNumber,
      });
    },
  });
};

export const useMarkUserOnboarding = (status: string) =>
  useMutation({
    mutationFn: () => markUserOnboarding(status),
  });

export const useChangeUserNotificationPreference = (
  toggleOn: boolean,
  type: string
) =>
  useMutation({
    mutationFn: () => changeUserNotificationPreference(toggleOn, type),
    onSuccess: () => {
      //runs subscribe/unsubscribe via Airship SDK to keep lists in sync locally
      toggleOn
        ? updateSubscriptionListSubscribe('sub_case_update_alerts_email')
        : updateSubscriptionListUnsubscribe('sub_case_update_alerts_email');
    },
  });

export const useSubmitCasePrompt = (input: Record<string, unknown>) =>
  useMutation({
    mutationFn: () => submitCasePrompt(input),
  });

export const useTrackUserEvent = (
  event: UserEventType,
  filter: UserEventInfoFilter
) => {
  // TODO: Possibly update query cache as a result of mutation
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => trackUserEvent(event),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['get-user-event-info', event, filter],
      });
    },
  });
};
